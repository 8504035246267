<template>
  <div>
    <v-footer color="red" dark app absolute>
      <v-container>
        <v-row align="start">
          <v-col cols="12" md="3">
            <h4 class="text-h5 font-weight-bold">
              CEDEPPE
            </h4>
            <p class="text-body-1">
              {{contato.email}}</br>
              {{contato.whatsapp}}
            </p>
          </v-col>

          <v-col cols="12" md="3">
            <v-list flat color="transparent">
              <v-subheader class="text-h6" style="cursor: pointer" @click="ir('Home?modelo=comofunciona')">Associar</v-subheader>
              <v-list-item-group color="primary">
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" md="3">
            <v-list flat color="transparent">
              <v-subheader class="text-h6" style="cursor: pointer" @click="ir('Treinamentos')">Treinamentos</v-subheader>
              <v-list-item-group color="primary">
                <!--<v-list-item
                  v-for="(item, i) in shock"
                  :key="i"
                  :href="item.href"
                  dense
                  target="_black"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-body-1"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" md="3">
            <div>
              <h5 class="text-h6 font-weight-bold">
                Assine nosso NewsLetter
              </h5>
              <p class="text-body-1">
                Assine nosso newsLetter e fique por dentro de tudo no CEDEPPE
              </p>
            </div>

            <v-row>
              <v-col cols="8">
                <v-text-field
                  label="email"
                  solo
                  light
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-btn large block color="secondary">
                  Assinar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <div class="text-center mt-6">
         Centro de Desenvolvimento Pessoal e Profissional de Executivos.
        </div>
        <div class="text-center mt-3">
          Copyright &copy;
          <a
            class="white--text"
            href="https://heroui.net"
            target="_blank"
            rel="noopener noreferrer"
            ></a
          >
          {{ new Date().getFullYear() }}. Todos os direitos reservados.
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import main from '../../assets/scripts/main.js';
export default {

  data: () => ({
    contato: {
      email: '',
      whatsapp: ''
    }
  }),
  async created() {
    //pegaInformacoes de contato
    var req = { setor: 'duvidas'}
    var resp = await main.postApi(req, "contatos/pegarContatos");
    var retorno2 = await resp.json();
    if(retorno2.sucesso){
      this.contato.email = retorno2.corpo.Email;
      this.contato.whatsapp = retorno2.corpo.WhatsApp;
    }
  },
   methods: {
    async ir(url){
      this.$router.push(url);
    }
   }
};
</script>
